import React, { useState } from "react"
import cx from "classnames"
import map from "lodash/map"
import uuid from "uuid/v4"
import Box from "@material-ui/core/Box"
import Hidden from "@material-ui/core/Hidden"
import MuiToolbar from "@material-ui/core/Toolbar"
import MenuIcon from "@material-ui/icons/Menu"
import MuiAppBar from "@material-ui/core/AppBar"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"

import { Link } from "gatsby"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"

import Drawer from "../drawer"

const Toolbar = withStyles({
  gutters: {
    padding: 0,
  },
})(MuiToolbar)

const AppBar = withStyles(theme => {
  return {
    colorPrimary: {
      backgroundColor: "transparent",
      transition: "background-color 300ms"
    },
  }
})(MuiAppBar)

const useStyles = makeStyles(theme => {
  return {
    appBar: {},
    toolbar: {
      backgroundColor: ({ isHomePage }) =>
        isHomePage ? "transparent" : theme.palette.info.main,
  
      alignItems: "center",
      justifyContent: "center",
  
      flexDirection: ({ isHomePage }) => (isHomePage ? "column" : "row"),
      
      [theme.breakpoints.down("sm")]: {
        justifyContent: "space-between",
        flexDirection: "row !important",
      },
    },
    link: {
      textDecoration: "none !important",
      color: theme.palette.secondary.main,
      padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
      margin: `0 ${theme.spacing(1)}px`,
      "&:hover": {
        borderRadius: ({ isHomePage }) => (isHomePage ? "50px" : "unset"),
        backgroundColor: ({ isHomePage }) => (isHomePage ? theme.palette.info.main : "unset"),
      }
    },
    activeLink: {
      color: theme.palette.common.white,
      backgroundColor: ({ isHomePage }) => (isHomePage ? theme.palette.info.main : "unset"),
      borderRadius: ({ isHomePage }) => (isHomePage ? "50px" : "unset"),
    },
    menuButton: {
      color: theme.palette.secondary.main,
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    nav: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    helper: {
      width: theme.spacing(7)
    },
    scroll: {
      "& [data-logo='container']": {
        display: "none",
        [theme.breakpoints.down("sm")]: {
          display: "flex",
        }
      },
    },
    idle: {
      "& [data-logo='container']": {
        display: "none",
        [theme.breakpoints.down("sm")]: {
          display: "flex",
        }
      },
    },
    homescroll: {
      backgroundColor: theme.palette.info.main,
      "& [data-logo='container']": {
        display: "none",
      },
      [theme.breakpoints.down("sm")]: {
        "& [data-logo='container']": {
          display: "flex",
        },
      }
    },
    homeidle: {
      backgroundColor: 'transparent',
      display: "flex",
    },
    logoContainer: {
      [theme.breakpoints.down("sm")]: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    logo: {
      [theme.breakpoints.down("sm")]: {
        width: theme.spacing(12.5)
      },
      [theme.breakpoints.down("xs")]: {
        width: theme.spacing(11.5)
      }
    },
  }
})

function ElevationScroll(props) {
  const { children, window, isHomePage } = props
  const classes = useStyles({ isHomePage })
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  })

  const isHome = isHomePage ? 'home' : ''

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
    className: trigger 
      ? cx({
        [classes.homescroll]: isHome,
        [classes.scroll]: !isHome,
      })
      : cx({
        [classes.homeidle]: isHome,
        [classes.idle]: !isHome
      })
  })
}

const links = [
  {
    slug: "/",
    label: "HOME",
  },
  {
    slug: "/aktualnosci/",
    label: "AKTUALNOŚCI",
  },
  {
    slug: "/cennik/",
    label: "CENNIK",
  },
  {
    slug: "/urodziny/",
    label: "URODZINY",
  },
  {
    slug: "/oferta/",
    label: "OFERTA",
  },
  {
    slug: "/galeria/",
    label: "GALERIA",
  },
  {
    slug: "/kontakt/",
    label: "KONTAKT",
  },
]

export default function ElevateAppBar(props) {
  const isHomePage = !!(props.location && props.location.pathname === "/")
  const classes = useStyles({ isHomePage })
  const [drawer, setDrawer] = useState(false)

  const { logo } = useStaticQuery(
    graphql`
      query {
        logo: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 129,
              height: 62,
              layout: CONSTRAINED,
              placeholder: NONE
            )
          }
        }
      }
    `
  )

  return (
    <React.Fragment>
      <ElevationScroll {...props} isHomePage={isHomePage}>
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <Hidden mdUp>
              <Box className={classes.helper}>&nbsp;</Box>
            </Hidden>

            <Box py={2} className={classes.logoContainer} data-logo="container">
              <Link to="/">
                <GatsbyImage alt="logo" image={getImage(logo)} className={classes.logo}/>
              </Link>
            </Box>

            <Box
              component="nav"
              display="flex"
              justifyContent="center"
              className={classes.nav}
            >
              {map(links, link => (
                <Link
                  key={uuid()}
                  to={link.slug}
                  className={classes.link}
                  activeClassName={classes.activeLink}
                >
                  <Typography component="span" variant="h2">
                    {link.label}
                  </Typography>
                </Link>
              ))}
            </Box>
            <IconButton
              edge="start"
              className={classes.menuButton}
              onClick={() => setDrawer(isOpen => !isOpen)}
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Drawer drawer={drawer} setDrawer={setDrawer} links={links} />
    </React.Fragment>
  )
}
