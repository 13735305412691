import React from "react"
import { Helmet } from "react-helmet"

const Seo = ({ description, lang, meta, title }) => (
  <Helmet
    htmlAttributes={{ lang }}
    title={title}
    // titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
    meta={[
      {
        name: `description`,
        content: description,
      },
      {
        property: `og:title`,
        content: title,
      },
      {
        property: `og:description`,
        content: description,
      },
      {
        property: `og:type`,
        content: `website`,
      },
      {
        name: `twitter:card`,
        content: `summary`,
      },
      // {
      //   name: `twitter:creator`,
      //   content: site.siteMetadata?.author || ``,
      // },
      {
        name: `twitter:title`,
        content: title,
      },
      {
        name: `twitter:description`,
        content: description,
      },
    ].concat(meta)}
  />
)

Seo.defaultProps = {
  lang: `pl`,
  meta: [],
  title: "Sala zabaw Kakadu",
  description: ``,
}

export default Seo
