import React from "react"

import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"

import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import { useStaticQuery, graphql } from "gatsby"

import Cloud from "../cloud"
import FacebookIcon from "../../images/facebook.svg"

const useStyles = makeStyles(theme => ({
  footer: {
    position: "relative",
    "& a": {
      color: theme.palette.common.white,
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
      "&:hover:active": {
        textDecoration: "underline",
      },
    },
  },
  columns: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(7),
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column-reverse",
    },
    "& ul": {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  columnsTitle: {
    fontWeight: "bold",
    margin: `${theme.spacing(4)}px 0`,
    [theme.breakpoints.down("sm")]: {
      margin: `${theme.spacing(3.75)}px 0`,
    },
    "&:first-of-type ~ h2": {
      marginTop: theme.spacing(5.25),
    },
  },
  copyrights: {
    backgroundColor: theme.palette.info.main,
  },
  copyrightsText: {
    padding: `${theme.spacing(1.5)}px 0`,
  },
  iconButton: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      padding: "0 5px 0 0",
      marginRight: theme.spacing(0.5),
      paddingLeft: 0,
    },
  },
  icon: {
    width: theme.spacing(6.25),
    height: theme.spacing(6.25),
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(5.25),
      height: theme.spacing(5.25),
    },
  },
  social: {
    fontSize: "18px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  socials: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: 20,
      marginTop: "unset",
    },
  },
  socialText: {
    textAlign: "center",
    textDecoration: "none !important",
    color: theme.palette.common.white,
    padding: "14px 25px",
    borderRadius: theme.spacing(6.25),
    backgroundColor: theme.palette.warning.main,
    "&:hover": {
      backgroundColor: theme.palette.warning.light,
    },
    "&:hover:active": {
      backgroundColor: theme.palette.warning.light,
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1.75),
    },
  },
  activeClassName: {
    textDecoration: "underline !important",
  },
}))

export default function Footer() {
  const classes = useStyles()

  const { datoCmsFooter } = useStaticQuery(graphql`
    query {
      datoCmsFooter {
        openHours
      }
    }
  `)

  return (
    <footer className={classes.footer}>
      <Box className={classes.columns}>
        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Typography
                variant="h1"
                component="h2"
                className={classes.columnsTitle}
              >
                STRONY
              </Typography>
              <Typography component="ul" variant="body1">
                <li>
                  <Link to="/" activeClassName={classes.activeClassName}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="/aktualnosci/"
                    activeClassName={classes.activeClassName}
                  >
                    Aktualności
                  </Link>
                </li>
                <li>
                  <Link to="/cennik/" activeClassName={classes.activeClassName}>
                    Cennik wejść
                  </Link>
                </li>
                <li>
                  <Link
                    to="/galeria/"
                    activeClassName={classes.activeClassName}
                  >
                    Galeria
                  </Link>
                </li>
                <li>
                  <Link
                    to="/kontakt/"
                    activeClassName={classes.activeClassName}
                  >
                    Kontakt
                  </Link>
                </li>
                <li>
                  <Link to="/oferta/" activeClassName={classes.activeClassName}>
                    Oferta
                  </Link>
                </li>
                <li>
                  <Link
                    to="/urodziny/"
                    activeClassName={classes.activeClassName}
                  >
                    Urodziny
                  </Link>
                </li>
                <li>
                  <Link
                    to="/wnetrze/"
                    activeClassName={classes.activeClassName}
                  >
                    Zobacz wnętrze
                  </Link>
                </li>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography
                variant="h1"
                component="h2"
                className={classes.columnsTitle}
              >
                REGULAMINY
              </Typography>
              <Typography component="ul" variant="body1">
                <li>
                  <Link
                    to="/regulamin-urodzin/"
                    activeClassName={classes.activeClassName}
                  >
                    Regulamin urodzin
                  </Link>
                </li>
                <li>
                  <Link
                    to="/regulamin-sali-zabaw/"
                    activeClassName={classes.activeClassName}
                  >
                    Regulamin sali zabaw
                  </Link>
                </li>
              </Typography>
              <Typography
                variant="h1"
                component="h2"
                className={classes.columnsTitle}
              >
                POLITYKA
              </Typography>
              <Typography component="ul" variant="body1">
                <li>
                  <Link
                    to="/polityka-cookies/"
                    activeClassName={classes.activeClassName}
                  >
                    Polityka cookies
                  </Link>
                </li>
                <li>
                  <Link
                    to="/polityka-prywatnosci/"
                    activeClassName={classes.activeClassName}
                  >
                    Polityka prywatności
                  </Link>
                </li>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography
                variant="h1"
                component="h2"
                className={classes.columnsTitle}
              >
                SALA ZABAW KAKADU
              </Typography>
              <Typography component="ul" variant="body1">
                <li>Piaseczno 05-500</li>
                <li>ul. Pawia 7 lok. 6</li>
                <li>
                  <a
                    href="tel:+48221213662"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    (+48) 22 121 36 62
                  </a>
                </li>
                <li>
                  <a
                    href="tel:+48661727779"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    (+48) 661 727 779
                  </a>
                </li>
                <li>
                  <a
                    href="mailto:salakakadu@gmail.com"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    salakakadu@gmail.com
                  </a>
                </li>
              </Typography>
              <Typography
                variant="h1"
                component="h2"
                className={classes.columnsTitle}
              >
                GODZINY OTWARCIA
              </Typography>
              {datoCmsFooter && datoCmsFooter.openHours && (
                <Typography
                  component="div"
                  variant="body1"
                  dangerouslySetInnerHTML={{ __html: datoCmsFooter.openHours }}
                />
              )}
            </Grid>
          </Grid>
        </Container>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          className={classes.socials}
        >
          <IconButton
            color="inherit"
            aria-label="social icon"
            className={classes.iconButton}
            href="https://www.facebook.com/KakaduSalaZabaw/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FacebookIcon className={classes.icon} />
          </IconButton>

          <Button
            href="https://www.facebook.com/KakaduSalaZabaw/"
            size="large"
            color="inherit"
            target="_blank"
            rel="noreferrer noopener"
            variant="contained"
            disableElevation
            className={classes.socialText}
          >
            <Typography
              component="span"
              variant="h2"
              className={classes.social}
            >
              POLUB NAS NA FACEBOOKU
            </Typography>
          </Button>
        </Box>
      </Box>
      <Box className={classes.copyrights}>
        <Container maxWidth="md">
          <Typography
            variant="body2"
            align="center"
            className={classes.copyrightsText}
          >
            Copyrights {new Date().getFullYear()}{" "}
            <Link to="/">SalaKakadu.pl</Link> - Wszelkie Prawa Zastrzeżone |
            projekt:{" "}
            <a
              rel="noreferrer noopener"
              href="https://mazak.studio/"
              target="_blank"
            >
              mazak.studio
            </a>
          </Typography>
        </Container>
      </Box>
      <Cloud position="left" />
    </footer>
  )
}
