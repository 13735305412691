import React from "react"

import Hidden from "@material-ui/core/Hidden"
import { makeStyles } from "@material-ui/core/styles"

import CloudIcon from "../../images/cloud.svg"

const useStyles = makeStyles(theme => ({
  root: {
    position: "absolute",
    top: 0,
    left: ({ position }) => (position === "left" ? 0 : "unset"),
    right: ({ position }) => (position === "right" ? 0 : "unset"),
    transform: ({ position }) =>
      position === "left" ? "translate(-50%, -50%)" : "translate(50%, -50%)",
  },
}))

const Cloud = ({ position }) => {
  const classes = useStyles({ position })
  return (
    <Hidden mdDown>
      <CloudIcon className={classes.root} />
    </Hidden>
  )
}
export default Cloud
