import React from "react"
import PropTypes from "prop-types"

import { ThemeProvider, createTheme, CssBaseline } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import AppBar from "../appBar"
import Footer from "../footer"

const theme = createTheme({
  typography: {
    fontSize: 18,
    fontFamily: ["Roboto", "sans-serif"].join(", "),
    body1: {
      fontSize: 18,
      fontFamily: ["Roboto", "sans-serif"].join(", "),
      "@media (min-width:600px)": {
        fontSize: 16,
      },
    },
    body2: {
      fontSize: 16,
      fontFamily: ["Roboto", "sans-serif"].join(", "),
      "@media (min-width:600px)": {
        fontSize: 14,
      },
    },
    h1: {
      fontSize: 22,
      fontFamily: ["Sigmar One", "cursive"].join(", "),
    },
    h2: {
      fontSize: 18,
      fontFamily: ["Sigmar One", "cursive"].join(", "),
      "@media (min-width:960px)": {},
    },
    h3: {
      fontSize: 16,
      fontFamily: ["Sigmar One", "cursive"].join(", "),
      "@media (min-width:960px)": {
        fontSize: 14,
      },
    },
    h4: {
      fontSize: 14,
      fontFamily: ["Sigmar One", "cursive"].join(", "),
      "@media (min-width:960px)": {
        fontSize: 12,
      },
    },
    h5: {
      fontSize: 12,
      fontFamily: ["Sigmar One", "cursive"].join(", "),
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        a: {
          color: "inherit",
          "&:hover": {
            color: "inherit",
          },
          "&:hover:active": {
            color: "inherit",
          },
        },
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(0, 0, 0, 0.7)",
      },
    },
  },
  palette: {
    error: {
      main: "#D22746",
    },
    success: {
      main: "#3DD330",
    },
    info: {
      main: "#70E8E4",
    },
    primary: {
      main: "#B136FF",
    },
    secondary: {
      main: "#E526DE",
    },
    warning: {
      main: "#FFD000",
    },
    text: {
      hint: "rgba(0, 0, 0, 0.38)",
      primary: "#fff", //"rgba(0, 0, 0, 0.87)",
      disabled: "rgba(0, 0, 0, 0.38)",
      secondary: "#E526DE", // "rgba(0, 0, 0, 0.54)",
    },
  },
})

const useStyles = makeStyles(theme => ({
  main: {
    overflow: "hidden",
    minHeight: `calc(100vh - 300px)`,

    paddingTop: ({ isHomePage }) => (isHomePage ? 0 : theme.spacing(8)),

    backgroundImage: ({ isHomePage }) =>
      isHomePage ? "unset" : `url("/clouds-desktop.png")`,

    backgroundRepeat: ({ isHomePage }) => (isHomePage ? "unset" : "repeat-x"),

    backgroundPositionY: ({ isHomePage }) =>
      isHomePage ? "unset" : theme.spacing(7.5),

    backgroundColor: ({ bgColor, isHomePage }) =>
      isHomePage ? "transparent" : bgColor || theme.palette.console.warning.main,

    [theme.breakpoints.down("sm")]: {
      paddingTop: ({ isHomePage }) => (isHomePage ? 0 : theme.spacing(7)),
      backgroundPositionY: ({ isHomePage }) =>
        isHomePage ? 0 : theme.spacing(6.5),
      backgroundImage: ({ isHomePage }) =>
        isHomePage ? "unset" : `url("/clouds-mobile.png")`,
    },
  },
}))

const Layout = ({ children, bgColor, location }) => {
  const isHomePage = !!(location && location.pathname === "/")
  const classes = useStyles({ bgColor, isHomePage })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar
        location={location}
      />
      <main className={classes.main}>{children}</main>
      <Footer />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
