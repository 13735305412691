import React from "react"
import uuid from "uuid/v4"
import map from "lodash/map"
import Box from "@material-ui/core/Box"
import CloseIcon from "@material-ui/icons/Close"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import MuiSwipeableDrawer from "@material-ui/core/SwipeableDrawer"

import { Link } from "gatsby"
import { makeStyles, withStyles } from "@material-ui/core/styles"

import FacebookIcon from "../../images/facebook.svg"

const SwipeableDrawer = withStyles(theme => {
  return {
    root: {
      width: "100%",
      maxWidth: 640,
    },
    paper: {
      width: "100%",
      maxWidth: 640,
      backgroundColor: theme.palette.info.main,
      overflow: "hidden",
    },
  }
})(MuiSwipeableDrawer)

const useStyles = makeStyles(theme => ({
  list: {
    paddingTop: theme.spacing(12),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
    justifyContent: "center",
  },
  listItem: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0.5),
    color: theme.palette.common.white,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
    "&:hover:active": {
      textDecoration: "underline",
    },
  },
  listItemActive: {
    color: theme.palette.secondary.main,
  },
  close: {
    top: theme.spacing(1),
    right: theme.spacing(3),
    color: theme.palette.secondary.main,
    fontSize: `${theme.spacing(5)}px`,
    position: "absolute",
  },
  link: {
    color: theme.palette.common.white,
  },
  icon: {
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    color: "inherit",
  },
}))

const SwipeableTemporaryDrawer = ({ drawer, setDrawer, links }) => {
  const classes = useStyles()

  const list = () => (
    <nav className={classes.list} role="presentation">
      {map(links, ({ label, slug }) => {
        return (
          <Link
            to={slug}
            key={uuid()}
            className={classes.listItem}
            activeClassName={classes.listItemActive}
            onClick={() => setDrawer(false)}
            onKeyDown={() => setDrawer(false)}
          >
            <Typography component="span" variant="h1">
              {label}
            </Typography>
          </Link>
        )
      })}
    </nav>
  )

  return (
    <SwipeableDrawer
      open={drawer}
      anchor="right"
      onClose={() => setDrawer(false)}
      onOpen={() => setDrawer(true)}
    >
      {list()}
      <Box display="flex" justifyContent="center" mt={6}>
        <IconButton
          rel="noreferrer noopener"
          href="https://www.facebook.com/KakaduSalaZabaw/"
          color="inherit"
          target="_blank"
          aria-label="social icon"
          className={classes.link}
        >
          <FacebookIcon className={classes.icon} />
        </IconButton>
      </Box>

      <IconButton
        onClick={() => setDrawer(false)}
        className={classes.close}
        aria-label="close"
      >
        <CloseIcon color="inherit" />
      </IconButton>
    </SwipeableDrawer>
  )
}

export default SwipeableTemporaryDrawer
